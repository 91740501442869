import React from "react"

import WOW from 'wowjs';
import { Helmet } from "react-helmet"
import { findDOMNode } from "react-dom";
import * as yup from 'yup';

import UserContext from "../userContext.js"

import Countdown from "react-countdown";

import { navigate } from "gatsby-link";

import LogoSlider from "../components/logoSlider.jsx";

import { Link } from "gatsby";

import UserTopbar from "../components/userTopbar";

import ilustration4 from '../images/ilustration-4.png'

import Footer from "../components/footer.jsx";


import mt1 from '../images/mt1.png'
import mt2 from '../images/mt2.png'
import mt3 from '../images/mt3.png'

import add from '../images/add.svg'

import filter from '../images/filter_icon.png'

import Topbar from '../components/topbar'
import SelectableList from '../components/selectableList'
import Switcher from '../components/switcher'
import CoverPicker from '../components/coverPicker'
import { APP_URI } from '../configs';
import { Dropdown, Checkbox } from 'semantic-ui-react'

import ImageUploading from 'react-images-uploading';

import ilustration from '../images/ilustration.jpg'
import phone from '../images/device-phone.svg'
import desktop from '../images/device-desktop.svg'
import console from '../images/device-console.svg'

import selectImage from '../images/select-image.svg'

import msjImage from '../images/msj_new-troop.svg'

import { register, register2, userExist } from '../userHelper.js'

import '../scss/myTroops.scss'
import 'semantic-ui-css/semantic.min.css'
import Selectable from "../components/selectable/index.jsx";
import SwitchButton from "../components/switchButton/index.jsx";

export default class feed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      errors: [],
      client: false,
    };
    this.references = {
      hero: React.createRef(),
      services: React.createRef(),
      numbers: React.createRef(),
      features: React.createRef(),
      logos: React.createRef(),
      contact: React.createRef(),
    }
  }


  handleNext = (user) => {
    var newValue = this.state.step + 1;
    if (this.state.step === 5) {
      newValue = 0;
    }


    this.setState({
      step: newValue
    })
  }

  handleBack = (e) => {
    if(this.state.step > 0){
      this.setState({step: (this.state.step - 1)})
    }
  }

  componentDidMount() {
    this.setState({
      client: true,
    })
    if (window.location.href.indexOf("create") > -1) {
      this.setState({
        step: 1
      })
    }
  }

  available_countries = () => {

    return [
      { key: '1', value: '1', text: "Argentina" },
      { key: '2', value: '2', text: 'Chile' },
      { key: '3', value: '3', text: 'Colombia' },
      { key: '4', value: '4', text: 'Ecuador' },
      { key: '5', value: '5', text: 'Peru' },
      { key: '6', value: '6', text: 'Uruguay' },
      { key: '7', value: '7', text: 'Paraguay' },
      { key: '8', value: '8', text: 'Bolivia' },
    ]
  }

  available_troopers = () => {

    return [
      { key: '1', value: '1', text: "Aydan_" },
      { key: '2', value: '2', text: 'r1vox' },
      { key: '3', value: '3', text: 'pixiee_97' },
      { key: '4', value: '4', text: 'tomi.gz' },
      { key: '5', value: '5', text: 'slaug-horse' },
      { key: '6', value: '6', text: 'lauti-casla' },
      { key: '7', value: '7', text: 'mike-retric' },
      { key: '8', value: '8', text: 'joseul_lol' },
    ]
  }

  render() {
    return (
      <>
        {this.state.client &&
          <>
            <div className="my--troops" >
              <UserContext.Consumer>
                {userObject => (
                  <>
                    <UserTopbar user={userObject} profilePic={userObject?.userObject?.picture || null} />
                    <Helmet>
                    </Helmet>
                    <div className="wrapper app">



                      <section className="troops--section">
                        <div className="linear--header">
                          <h3>
                            Mis troops
                          </h3>
                          <SwitchButton text={'Creá tu troop'} onClick={() => this.setState({ step: 1 })} icon={add} />
                        </div>
                        <p className="header--p">
                          Estas son todas las tropos a las que pertenecés.
                        </p>
                        <div onClick={() => navigate('/isurus-gaming')} className="troops--list">
                          <img className="troop" src={mt1} />
                          <img className="troop" src={mt2} />
                          <img className="troop" src={mt3} />
                        </div>
                      </section>



                    </div>
                    {this.state.step > 0 &&
                      <section ref={this.references.features} className="signup--section">
                        <div className="wrapper">
                          <div className="signup--box">
                            <div className="back--container">
                              <p onClick={() => this.handleBack()}>Volver atras</p>
                              <div className="stepper">
                                <div className={this.state.step == 1 ? "step--circle active" : "step--circle"}>1</div>
                                <div className="step--line" />
                                <div className={this.state.step == 2 ? "step--circle active" : "step--circle"}>2</div>
                                <div className="step--line" />
                                <div className={this.state.step == 3 ? "step--circle active" : "step--circle"}>3</div>
                                <div className="step--line" />
                                <div className={this.state.step == 4 ? "step--circle active" : "step--circle"}>4</div>
                                <div className="step--line" />
                                <div className={this.state.step == 5 ? "step--circle active" : "step--circle"}>5</div>
                              </div>
                            </div>
                            {this.state.step === 1 &&
                              <div className="step step-1">
                                <h2 className="signup--title">Seguí los pasos para
                                  crear tu propia troop</h2>
                                <p className="input--title">Elegí un nombre</p>
                                <div className={`ui input ${this.state.errors.includes('email') ? 'error' : ''}`}><input type="email" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} placeholder="" /></div>
                                {this.state.errors.includes('email') && <div className="error--message"><p>Entrada invalida</p></div>}
                                <p className="input--title">Seleccióná tu país</p>
                                <div className="row date">
                                  <Dropdown key="day" placeholder="Pais" fluid value={this.state.day} onChange={this.handleDayChange} selection options={this.available_countries()} />
                                </div>
                                <div className="terms--container">
                                  <Checkbox />
                                  <p>He leído y acepto todos los <span className="bold">Términos y Condiciones </span> de TROOP.</p>
                                </div>
                              </div>
                            }
                            {this.state.step === 2 &&
                              <div className="step step-1">
                                <h2 className="signup--title">Seguí los pasos para
                                  crear tu propia troop</h2>
                                <p className="input--title">Invitá troopers a que se unan</p>
                                <Dropdown key="day" placeholder="Troopers" fluid search selection multiple value={this.state.day} onChange={this.handleDayChange} selection options={this.available_troopers()} />
                                <div className="terms--container">
                                  <Checkbox />
                                  <p>He leído y acepto todos los <span className="bold">Términos y Condiciones </span> de TROOP.</p>
                                </div>
                              </div>
                            }
                            {this.state.step === 3 &&
                              <div className="step step-1">
                                <h2 className="signup--title">Bienvenido a Troop</h2>
                                <p className="signup--subtitle">¡Hora de armar tu perfil!
                                  Seguí los pasos a continuación. </p>
                                <p className="input--title"><span className='bold'>Seleccioná</span> los videojuegos que más juegues.</p>
                                <div className="games--selectable--container">
                                  <SelectableList selectablesList={['CSGO', 'Valorant', 'GTA V', 'FIFA 21', 'CSGO', 'Valorant', 'CSGO', 'Valorant', 'GTA V', 'FIFA 21', 'CSGO', 'Valorant', 'GTA V', 'FIFA 21']} />
                                </div>
                                <p className="input--title">Si tu juego no se encuentra en la lista, podés escribirlo acá.</p>
                                <div className="ui input"><input type="text" placeholder="" /></div>
                              </div>
                            }
                            {this.state.step === 4 &&
                              <div className="step step-1">
                                <div className="profile--look--and--feel">
                                  <ImageUploading value={this.state.images} onChange={this.handleImageUpload}>
                                    {({ imageList, onImageUpload, onImageRemoveAll }) => (
                                      <div>
                                        <button className="profile--picture--uploader" onClick={onImageUpload}>
                                          {this.state.images?.length > 0 &&
                                            <img src={this.state.images[0].dataURL} />
                                          }
                                          {this.state.images?.length == 0 &&
                                            <img className="select--image" src={selectImage} />
                                          }

                                        </button>

                                      </div>

                                    )}
                                  </ImageUploading>
                                  <div className="cover--image">
                                    <CoverPicker value={this.state.cover} onChange={this.handleCoverChange} />
                                  </div>
                                </div>
                                <p className="input--title">Si tu juego no se encuentra en la lista, podés escribirlo acá.</p>
                                <div className="ui input"><input type="text" placeholder="" /></div>
                              </div>
                            }
                            {this.state.step === 5 &&
                              <div className="step step-1">
                                <img src={msjImage} />
                              </div>
                            }
                            <button className="button--global" onClick={() => this.handleNext(userObject)}>{this.state.step < 5 ? 'Siguiente' : 'Finalizar'}</button>
                          </div>
                        </div>
                      </section>
                    }
                  </>
                )}
              </UserContext.Consumer>
            </div>
            <Footer />
          </>
        }
      </>
    );
  }
}
